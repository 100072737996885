@import "plugins/bootstrap/bootstrap";
//@import "../js/plugins/lightbox/lightbox.scss";

@mixin gradient1{
    background: rgba(252,252,252,1);
    background: -moz-linear-gradient(left, rgba(252,252,252,1) 0%, rgba(222,232,242,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(252,252,252,1)), color-stop(100%, rgba(222,232,242,1)));
    background: -webkit-linear-gradient(left, rgba(252,252,252,1) 0%, rgba(222,232,242,1) 100%);
    background: -o-linear-gradient(left, rgba(252,252,252,1) 0%, rgba(222,232,242,1) 100%);
    background: -ms-linear-gradient(left, rgba(252,252,252,1) 0%, rgba(222,232,242,1) 100%);
    background: linear-gradient(to right, rgba(252,252,252,1) 0%, rgba(222,232,242,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcfcfc', endColorstr='#dee8f2', GradientType=1 );
}

@mixin gradient2{


    background: rgba(253,253,253,1);
    background: -moz-linear-gradient(-45deg, rgba(253,253,253,1) 0%, rgba(253,253,253,1) 37%, rgba(236,242,248,1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(253,253,253,1)), color-stop(37%, rgba(253,253,253,1)), color-stop(100%, rgba(236,242,248,1)));
    background: -webkit-linear-gradient(-45deg, rgba(253,253,253,1) 0%, rgba(253,253,253,1) 37%, rgba(236,242,248,1) 100%);
    background: -o-linear-gradient(-45deg, rgba(253,253,253,1) 0%, rgba(253,253,253,1) 37%, rgba(236,242,248,1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(253,253,253,1) 0%, rgba(253,253,253,1) 37%, rgba(236,242,248,1) 100%);
    background: linear-gradient(135deg, rgba(253,253,253,1) 0%, rgba(253,253,253,1) 37%, rgba(236,242,248,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fdfdfd', endColorstr='#ecf2f8', GradientType=1 );
}

@mixin gradient3{
    background: rgba(249,162,49,1);
    background: -moz-linear-gradient(left, rgba(249,162,49,1) 0%, rgba(245,130,53,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(249,162,49,1)), color-stop(100%, rgba(245,130,53,1)));
    background: -webkit-linear-gradient(left, rgba(249,162,49,1) 0%, rgba(245,130,53,1) 100%);
    background: -o-linear-gradient(left, rgba(249,162,49,1) 0%, rgba(245,130,53,1) 100%);
    background: -ms-linear-gradient(left, rgba(249,162,49,1) 0%, rgba(245,130,53,1) 100%);
    background: linear-gradient(to right, rgba(249,162,49,1) 0%, rgba(245,130,53,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9a231', endColorstr='#f58235', GradientType=1 );
}

@mixin fill{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin anim{
    transition: 0.3s all ease;
}

@mixin vertical{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
}

@mixin bg_pos{
    background-repeat: no-repeat;
    background-position: center;
}

@mixin shadow{
    box-shadow: 0px 9px 15px 0px rgba(141, 164, 206, 0.15);
}

$main_font:'Nunito Sans', sans-serif;
$accent_color1:#f97331;
$font_color1:#0c0c0c;
$font_color2:#646464;
$white:#ffffff;
$border_color:#dbe0e2;

body{
    &.scroll_block{
        overflow: hidden;
    }
    font-family: $main_font;
    color: $font_color2;
    font-size: 18px;
    line-height: 30px;
    @media only screen and (max-width: 1500px) {
        font-size: 15px;
        line-height: 27px;
    }
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        margin: 0;
        font-weight: 400;
    }
    ul{
        padding: 0;
        list-style: none;
    }
    a{
        text-decoration: none !important;
    }
    img{
        max-width: 100%;
        height: auto;
    }
}

.page{
    overflow: hidden;
}

.default_font{
    font-size: 15px;
    line-height: 35px;
    @media only screen and (max-width: 1500px) {
        font-size: 13px;
        line-height: 30px;
    }
    h1{
        font-size: 68px;
        line-height: 78px;
        @media only screen and (max-width: 1500px) {
            font-size: 48px;
            line-height: 58px;
        }
        @media only screen and (max-width: 1200px) {
            font-size: 30px;
            line-height: 40px;
        }
    }
    h2{
        font-size: 48px;
        line-height: 58px;
        @media only screen and (max-width: 1500px) {
            font-size: 30px;
            line-height: 40px;
        }
    }
    h3{
        font-size: 30px;
        line-height: 40px;
        @media only screen and (max-width: 1500px) {
            font-size: 24px;
            line-height: 34px;
        }
    }
    h4{
        font-size: 24px;
        line-height: 34px;
        @media only screen and (max-width: 1500px) {
            font-size: 21px;
            line-height: 31px;
        }
    }
    h5{
        font-size: 21px;
        line-height: 31px;
        @media only screen and (max-width: 1500px) {
            font-size: 18px;
            line-height: 28px;
        }
    }
    h6{
        font-size: 18px;
        line-height: 28px;
        @media only screen and (max-width: 1500px) {
            font-size: 16px;
            line-height: 26px;
        }
    }
    ul{
        > li{
            padding-left: 24px;
            position: relative;
            line-height: 24px;
            margin-bottom: 20px;
            color: $font_color1;
            &:last-child{
                margin-bottom: 0;
            }
            &:before{
                content:'';
                position: absolute;
                top: 5px;
                left: 0;
                height: 11px;
                width: 16px;
                @include bg_pos;
                background-image: url("./../img/check_icon.png");
            }
        }
    }
    ol{
        padding-left: 18px;
    }
    a{
        color: $accent_color1;
    }
    h1,h2,h3,h4,h5,h6{
        color: $font_color1;
        font-weight: 800;
    }
    h1,h2,h3,h4,h5,h6,p,ul,ol,a,img{
        margin-bottom: 30px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 15px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.home_top{
    height: 100vh;
    width: 100%;
    @include gradient1;
    .config_top_holder{
        padding-top: 149px;
        iframe{
            border:none;
        }
        .image{
            height: 100%;
            width: 100%;
            @include bg_pos;
            background-size: contain;
            background-image: url("./../img/slider_placeholder.png");
        }
    }
    @media only screen and (max-width: 991px) {
        height: auto;
    }
    .steps{
        background: $white;
        padding: 50px 0;
        @media only screen and (max-width: 1500px) {
            padding:30px 0;
        }
        .steps_holder{
            display: table;
            width: 100%;
            @media only screen and (max-width: 991px) {
                display: block;
            }
            > div{
                display: table-cell;
                vertical-align: middle;
                width: 20%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    display: block;
                    margin-bottom: 30px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                &.step_separate{
                    text-align: center;
                    @media only screen and (max-width: 1200px) {
                        display: none;
                    }
                }
                &.step_box{
                    min-height: 70px;
                    padding-left: 100px;
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: 600;
                    background-repeat: no-repeat;
                    background-position: left top;
                    @media only screen and (max-width: 1700px) {
                        padding-left: 80px;
                    }
                    @media only screen and (max-width: 1500px) {
                        font-size: 14px;
                        background-size: 32px;
                        padding-left: 45px;
                        vertical-align: top;
                    }
                    @media only screen and (max-width: 1200px) {
                        width: 33.33%;
                    }
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        background-position: center top;
                        text-align: center;
                        padding-left: 0;
                        padding-top: 45px;
                    }
                    p{
                        font-weight: inherit;
                    }
                    h2{
                        font-size: 24px;
                        line-height: 34px;
                        color: $font_color1;
                        font-weight: 800;
                        margin-bottom: 5px;
                        @media only screen and (max-width: 1500px) {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}

.more_btn{
    display: inline-block;
    line-height: 44px;
    font-size: 14px;
    font-weight: 700;
    @include gradient3;
    padding: 0 38px;
    color: $white;
    border-radius: 5px;
    text-transform: uppercase;
    @media only screen and (max-width: 1500px) {
        font-size: 12px;
        padding: 0 30px;
    }
    /*@media only screen and (max-width: 1200px) {
        padding: 0 20px;
        line-height: 38px;
    }
    @media only screen and (max-width: 991px) {
        font-size: 10px;
        padding: 0 15px;
        line-height: 30px;
    }*/
}

.page_header{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 40px 0;
    @include gradient1;
    z-index: 3;
    @media only screen and (max-width: 1500px) {
        padding: 20px 0;
    }
    .more_btn{
        @media only screen and (max-width: 1200px) {
            padding: 0 20px;
            line-height: 38px;
        }
        @media only screen and (max-width: 991px) {
            font-size: 10px;
            padding: 0 15px;
            line-height: 30px;
        }
    }
    .page_logos{
        position: relative;
        ul{
            margin: 0 -60px;
            @media only screen and (max-width: 1700px) {
                margin: 0 -40px;
            }
            @media only screen and (max-width: 1500px) {
                //margin: 0 -30px;
            }
            @media only screen and (max-width: 1200px) {
                margin: 0 -20px;
            }
            @media only screen and (max-width: 991px) {
                margin: 0;
            }
            li{
                padding: 17px 60px 17px 60px;
                display: inline-block;
                vertical-align: top;
                @media only screen and (max-width: 1700px) {
                    padding: 17px 40px 17px 40px;
                }
                @media only screen and (max-width: 1500px) {
                    padding: 17px 30px;
                }
                @media only screen and (max-width: 1200px) {
                    padding: 7px 20px;
                    img{
                        max-width: 85px;
                    }
                }
                @media only screen and (max-width: 991px) {
                    padding: 7px 15px;
                    &:first-child{
                        padding-left: 0;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
                &:first-child{
                    border-right: 1px solid $border_color;
                }
            }
        }
        @media only screen and (max-width: 991px) {
            .rwd_btn{
                width: 30px;
                position: absolute;
                right: 0;
                top: 10px;
                > div{
                    width: 100%;
                    height: 3px;
                    margin-bottom: 3px;
                    background: $accent_color1;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .navigation{
        padding-top: 13px;
        text-align: right;
        @media only screen and (max-width: 1200px) {
            padding-top: 3px;
        }
        @media only screen and (max-width: 991px) {
            padding-top: 15px;
        }
        > ul{
            @media only screen and (max-width: 991px) {
                display: flex;
                flex-flow: row;
                align-items: stretch;
                flex-wrap: wrap;
                justify-content: flex-end;
            }
            > li{
                display: inline-block;
                vertical-align: middle;
                &.go_to_configurator, &.menu_box{
                    margin-right: 60px;
                    @media only screen and (max-width: 1700px) {
                        margin-right: 40px;
                    }
                    @media only screen and (max-width: 1500px) {
                        margin-right: 20px;
                    }
                    @media only screen and (max-width: 991px) {
                        margin-right: 15px;
                    }
                }
                @media only screen and (max-width: 991px) {
                    &.go_to_configurator{
                        order: 1;
                    }
                }
                &.menu_box{
                    @media only screen and (max-width: 991px) {
                        order: 4;
                        width: 100%;
                        text-align: center;
                        margin-right: 0 !important;
                        padding-top: 15px;
                    }
                    .menu{
                        @media only screen and (max-width: 991px) {
                            padding: 15px;
                            background: $white;
                            @include shadow;
                            display: none;
                        }
                        ul{
                            li{
                                display: inline-block;
                                vertical-align: top;
                                margin-right: 60px;
                                @media only screen and (max-width: 1700px) {
                                    margin-right: 40px;
                                }
                                @media only screen and (max-width: 1500px) {
                                    margin-right: 20px;
                                }
                                @media only screen and (max-width: 1200px) {
                                    margin-right: 15px;
                                }
                                @media only screen and (max-width: 991px) {
                                    margin-right: 0;
                                    width: 100%;
                                    margin-bottom: 15px;
                                    &:last-child{
                                        margin-bottom: 0;
                                    }
                                }
                                &:last-child{
                                    margin-right: 0;
                                }
                                div{
                                    a{
                                        color: $font_color1;
                                        font-size: 14px;
                                        line-height: 14px;
                                        font-weight: 700;
                                        text-transform: uppercase;
                                        @media only screen and (max-width: 1500px) {
                                            font-size: 12px;
                                        }
                                        @media only screen and (max-width: 991px) {
                                            font-size: 15px;
                                            line-height: 40px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.social{
                    @media only screen and (max-width: 991px) {
                        order: 2;
                    }
                    ul{
                        li{
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 20px;
                            @media only screen and (max-width: 1200px) {
                                margin-right: 15px;
                                img{
                                    max-width: 25px;
                                }
                            }
                        }
                    }
                }
                &.langs{
                    @media only screen and (max-width: 991px) {
                        order: 3;
                    }
                    .dropdown{
                        border-radius: 50px;
                        &.open{
                            .dropdown-menu{
                                top: 50%;
                                opacity: 1;
                                visibility: visible;

                            }
                        }
                        .btn{
                            padding: 0;
                            width: 70px;
                            height: 30px;
                            border-radius: 50px;
                            background: $white;
                            color: #979797;
                            font-size: 12px;
                            font-weight: 700;
                            position: relative;
                            z-index: 1111;
                            border: 2px solid $white;
                            .caret{
                                border:none;
                                height: 4px;
                                width: 7px;
                                @include bg_pos;
                                background-image: url("./../img/langs_arr.png");
                            }
                        }
                        .dropdown-menu{
                            top: 0%;
                            padding: 20px 0 5px 0;
                            min-width: 0;
                            text-align: center;
                            display: block;
                            visibility: hidden;
                            opacity: 0;
                            width: 100%;
                            border:1px solid $white;
                            @include anim;
                            > li{
                                width: 100%;
                                > a{
                                    padding: 0;
                                    font-size: 11.5px;
                                    line-height: 30px;
                                    @include anim;
                                    position: relative;
                                    color: #979797;
                                    &:hover{
                                        background: $accent_color1;
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.arrs{
    text-align: right;
    ul{
        li{
            display: inline-block;
            vertical-align: top;
            width: 28px;
            height: 13px;
            @include bg_pos;
            @include anim;
            cursor: pointer;

            &.arr_left{
                background-image: url("./../img/c_arr_left.png");
                margin-right: 20px;
                &:hover{
                    background-image: url("./../img/c_arr_left_h.png");
                }
            }
            &.arr_right{
                background-image: url("./../img/c_arr_right.png");
                &:hover{
                    background-image: url("./../img/c_arr_right_h.png");
                }
            }
        }
    }
}

.partners{
    border-top: 1px solid $border_color;
    height: 225px;
    @include vertical;
    @include bg_pos;
    background-size: cover;
    background-image: url("./../img/part_bg.jpg");
    @media only screen and (max-width: 1500px) {
        height: 200px;
    }
    @media only screen and (max-width: 991px) {
        display: block;
        height: auto;
    }
    .carousel_content{
        overflow: hidden;
        > ul{
            position: relative;
            white-space: nowrap;
            li{
                display: inline-block;
                vertical-align: middle;
                width: calc(100% / 7);
                @media only screen and (max-width: 1500px) {
                    width: calc(100% / 5);
                }
                @media only screen and (max-width: 1200px) {
                    width: calc(100% / 3);
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                a{
                    display: block;
                    padding: 0 30px;
                    .image{
                        @include bg_pos;
                        background-size: contain;
                        height: 115px;
                    }
                }
            }
        }
    }
    .arrs{
        padding-top: 40px;
        @media only screen and (max-width: 991px) {
            padding: 20px 0;
            ul{
                li{
                    width: 50%;
                    &.arr_left{
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .side_left, .side_right{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.short_about{
    .short_about_box{
        //min-height: 75vh;
        min-height: 671px;
        display: flex;
        @media only screen and (max-width: 991px) {
            min-height: 0;
            flex-flow: column;
            align-items: stretch;
        }
        > div{
            width: 50%;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            &.image{
                background-size: cover;
                @include bg_pos;

                @media only screen and (max-width: 991px) {
                    order: 1;
                    width: 100%;
                    height: 53vh;
                    position: relative;
                }
                .rwd_play_btn{
                    display: none;
                    @media only screen and (max-width: 991px) {
                        @include vertical;
                        @include fill;
                        > div{
                            width: 100%;
                            > div{
                                text-align: center;
                            }
                        }
                    }
                }
                &.innder_image_holder{
                    @include vertical;
                    padding-left: 20px;
                    @media only screen and (max-width: 991px) {
                        padding-left: 0;
                    }
                    img{
                        position: relative;
                        z-index: 2;
                    }
                }
            }
            &.desc{
                padding-top:40px;
                padding-bottom: 40px;
                @include vertical;
                position: relative;
                @media only screen and (max-width: 991px) {
                    order: 2;
                    ul{
                        padding-top: 15px;
                    }
                }
                .cover{
                    width: 12%;
                    position: absolute;
                    top: 0;
                    right: 100%;
                    height: 100%;
                    @include vertical;
                    > div{
                        width: 100%;
                        height: 85%;
                        @include vertical;
                    }
                }
                .desc_holder{
                    width: 100%;
                }
            }
        }
        &.desc_right{
            div.desc{
                padding-left: 4.5%;
                .cover{
                    right: 100%;
                    > div{
                        background: $white;
                        .play_btn{
                            cursor: pointer;
                          position: relative;
                            left: -50%;
                        }
                    }
                }
            }
        }
        &.desc_left{
            @include gradient1;
            div.desc{
                padding-right: 4.5%;
                @media only screen and (max-width: 991px) {
                    padding-right: 0;
                }
                > .default_font{
                    .row{
                        margin: 0 -50px;
                        > div{
                            padding: 0 50px;
                        }
                    }
                }
                .cover{
                    left: 100%;
                    > div{
                        background: #ecf1f6;
                    }
                }
            }
        }
    }
}

.section_header{
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 50px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 30px;
        font-size: 13px;
        line-height: 23px;
    }
    &.large{
        h2{
            font-size: 68px;
            line-height: 78px;
            color: $font_color1;
            @media only screen and (max-width: 1500px) {
                font-size: 48px;
                line-height: 58px;
            }
        }
    }
    p{
        color:$accent_color1;
        text-transform: uppercase;
        margin-bottom: 15px;
        letter-spacing: 1px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 0;
        }
    }
    strong{
        font-weight: 800;
    }
    &.with_line{
        display: flex;
        flex-wrap: nowrap;
        align-content: stretch;
        &.purpe{
            .linee{
                background: #e7e1f4;
            }
        }
        &.orange{
            .linee{
                @include gradient3;
            }
        }
        > div{
            &:first-child{
                padding-right: 30px;
                white-space: nowrap;
            }
            &:last-child{
                width: 100%;
                @include vertical;
                padding-top: 30px;
            }
            .linee{
                height: 3px;
                width: 100%;
                background: #e7e1f4;
            }
        }
    }
}

.development{
    display: flex;
    //min-height: 90vh;
    min-height: 671px;
    @media only screen and (max-width: 991px) {
        min-height: 0;
        flex-flow: column;
        align-items: stretch;
    }
    > div{
        padding-top: 80px;
        width: 50%;
        @media only screen and (max-width: 1500px) {
            padding-top: 40px;
        }
        @media only screen and (max-width: 991px) {
            width: 100%;
        }
    }
    div.desc{
        padding-right: 5vw;
        @media only screen and (max-width: 991px) {
            order: 1;
            margin-bottom: 50px;
        }
       .short_desc{
           margin-bottom: 50px;
           @media only screen and (max-width: 1500px) {
               margin-bottom: 30px;
           }
           p{
               margin-bottom: 30px;
               @media only screen and (max-width: 1500px) {
                   margin-bottom: 15px;
               }
               &:last-child{
                   margin-bottom: 0;
               }
           }
       }
    }
    .development_carousel{
        padding-left: 5vw;
        padding-right: 5vw;
        @media only screen and (max-width: 991px) {
            order: 2;
            padding-left: 15px;
            padding-right: 15px;
        }
        @include bg_pos;
        background-size: cover;
        background-image:url("./../img/home_bg3.jpg");
        .carousel_content{
            padding-top: 20px;
            overflow: hidden;
            margin-bottom: 50px;
            @media only screen and (max-width: 1500px) {
                padding-top: 0;
                padding-bottom: 50px;
                margin-bottom: 0;
            }
            .arrs{
                margin-bottom: 15px;
            }
            > ul{
                position: relative;
                white-space: nowrap;
                > li{
                    white-space: normal;
                    display: inline-block;
                    vertical-align: top;
                    width: 100%;
                    margin-right: 5%;
                    .grid_4{
                        display: grid;
                        grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
                        grid-gap: 35px;
                        grid-auto-rows: minmax(220px, auto);
                        grid-auto-flow: dense;
                        @media only screen and (max-width: 1500px) {
                            grid-gap: 20px;
                            grid-auto-rows: minmax(200px, auto);
                        }
                        @media only screen and (max-width: 991px) {
                            grid-auto-rows: minmax(24vh, auto);
                            grid-gap: 10px;
                        }
                        .grid_item{
                            @include bg_pos;
                            background-size: cover;
                            position: relative;
                            border-radius: 10px;
                            overflow: hidden;
                            &:hover{
                                .desc{
                                    &:after{
                                        opacity: 1;
                                    }
                                    .grid_short_desc{
                                        .default_font{
                                            margin-left: 0;
                                        }
                                    }
                                }
                            }
                        }
                        .desc{
                            @include fill;
                            @include anim;
                            background: rgba(0,0,0,0.5);
                            padding: 30px 0;
                            @media only screen and (max-width: 1500px) {
                                padding: 20px 0;
                            }
                            @media only screen and (max-width: 991px) {
                                padding: 10px 0;
                                @include vertical;
                                > div{
                                    width: 100%;
                                }
                            }
                            &:after{
                                content: '';
                                @include fill;
                                @include gradient3;
                                @include anim;
                                opacity: 0;
                            }
                            > div{
                                padding: 0 30px;
                                position: relative;
                                z-index: 2;
                                @media only screen and (max-width: 991px) {
                                    padding: 0 10px;
                                }
                                header{
                                    margin-bottom: 20px;
                                    @media only screen and (max-width: 991px) {
                                        text-align: center;
                                        .default_font{
                                            h3{
                                                font-size: 17px;
                                            }
                                        }
                                    }
                                }
                                .grid_short_desc{
                                    overflow: hidden;
                                    > div{
                                        display: inline-block;
                                        vertical-align: bottom;
                                        &.default_font{
                                            width: calc(100% - 44px);
                                            line-height: 24px;
                                            height: 90px;
                                            @include anim;
                                            margin-left: calc(-100% + 44px);
                                            @media only screen and (max-width: 991px) {
                                                display: none;
                                            }
                                            p{
                                                display: -webkit-box;
                                                -webkit-line-clamp: 3;
                                                -webkit-box-orient: vertical;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                            }
                                        }
                                        &.arr{
                                            width: 44px;
                                            height: 0;
                                            padding-bottom: 44px;
                                            @include bg_pos;
                                            background-size: cover;
                                            background-image: url("./../img/more_arr1.png");
                                            @media only screen and (max-width: 991px) {
                                                width: 100%;
                                                background-size: contain;
                                            }
                                        }
                                    }
                                }
                                .default_font{
                                    color: $white;
                                    h1,h2,h3,h4,h5,h6{
                                        color: inherit;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.special_offer_slider{
    height: 480px;
    width: 100%;
    position: relative;
    @media only screen and (max-width: 1500px) {
        height: 400px;
    }
    @media only screen and (max-width: 991px) {
        height: 300px;
    }
    > ul{
        @include fill;
        > li{
            @include fill;
            > div{
                @include fill;
                > div{
                    @include fill;
                    &.image{
                        @include bg_pos;
                        background-size: cover;
                    }
                    &.desc{
                        z-index: 2;
                        background: rgba(0,0,0,0.4);
                        @include vertical;
                        color: $white;
                        .section_header{
                            margin-bottom: 0;
                            @media only screen and (max-width: 991px) {
                                margin-bottom: 20px;
                            }
                            div.default_font{
                                color: $white;
                                *{
                                    color: inherit;
                                }
                            }
                        }
                        .container{
                            margin-bottom: 90px;
                            @media only screen and (max-width: 1500px) {
                                margin-bottom: 70px;
                            }
                            .row{
                                width: 100%;
                                display: table;
                                margin: 0;
                                @media only screen and (max-width: 991px) {
                                    display: block;
                                }
                                > div{
                                    display: table-cell;
                                    vertical-align: middle;
                                    float: none;
                                    padding: 0;
                                    @media only screen and (max-width: 991px) {
                                        display: block;
                                        text-align: center;
                                    }
                                    .short_desc{
                                        padding: 0 5%;
                                        @media only screen and (max-width: 991px) {
                                            display: none;
                                        }
                                        p{
                                            margin-bottom: 30px;
                                            &:last-child{
                                                margin-bottom: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .special_offer_slider_nav{
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        height: 90px;
        border-top: 1px solid rgba(255,255,255,0.3);
        width: 100%;
        @include vertical;
        @media only screen and (max-width: 1500px) {
            height: 70px;
        }
        @media only screen and (max-width: 991px) {
            .container{
                width: 100%;
            }
        }
        .dots{
            ul{
                li{
                    cursor: pointer;
                    width: 9px;
                    height: 0;
                    padding-bottom: 9px;
                    background: #6f7a81;
                    @include anim;
                    margin-right: 18px;
                    display: inline-block;
                    vertical-align: top;
                    border-radius: 50%;
                    &.on{
                        background: $white;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
        .arrs{
            ul{
                li{
                    &.arr_left{
                        background-image: url("./../img/arr_white_left.png");
                    }
                    &.arr_right{
                        background-image: url("./../img/arr_white_right.png");
                    }
                }
            }
        }
    }
}

.reviews{
    padding: 100px 0;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0;
    }
    .carousel_content{
        overflow: hidden;
        padding-bottom: 20px;
        padding-left: 40px;
        @media only screen and (max-width: 991px) {
            padding-top: 30px;
        }
        .arrs{
            margin-bottom: 40px;
        }
        > ul{
            position: relative;
            white-space: nowrap;
            //margin: 0 -40px;
            > li{
                white-space: normal;
                //padding: 0 40px;
                display: inline-block;
                vertical-align: top;
                width: 30%;
                margin-right: 5%;
                @media only screen and (max-width: 1200px) {
                    width: 45%;
                    margin-right: 10%;
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                div.review_box{
                    border: 1px solid $border_color;
                    border-radius: 10px;
                    padding: 35px 45px;
                    @include shadow;
                    position: relative;
                    .image{
                        width: 77px;
                        height: 77px;
                        @include bg_pos;
                        background-size: cover;
                        border-radius: 50%;
                        border: 7px solid $white;
                        position: absolute;
                        left: -38.5px;
                        top: -38.5px;
                    }
                    header{
                        color: $font_color1;
                        margin-bottom: 15px;
                        line-height: 25px;
                        span{
                            font-size: 12px;
                            color: $font_color2;
                            font-weight: 600;
                        }
                    }
                    .desc{
                        font-size: 13px;
                        line-height: 23px;
                        font-weight: 600;
                        p{
                            font-weight: inherit;
                        }
                    }
                }
            }
        }
    }
}

.page_footer{
    .banner{
        height: 400px;
        width: 100%;
        @include vertical;
        position: relative;
        @media only screen and (max-width: 991px) {
            height: 300px;
        }
        > div{
            @include fill;
            &.image{
                @include bg_pos;
                background-size: cover;
            }
            &.desc{
                z-index: 2;
                background: rgba(0,0,0,0.4);
                @include vertical;
                color: $white;
                width: 100%;
                .banner_header{
                    color: $white;
                    font-size: 44px;
                    line-height: 64px;
                    @media only screen and (max-width: 1200px) {
                        font-size: 30px;
                        line-height: 40px;
                    }
                    @media only screen and (max-width: 991px) {
                        margin-bottom: 20px;

                    }
                    p{
                        font-weight: 800;
                    }
                }
                .container{
                    .row{
                        width: 100%;
                        display: table;
                        margin: 0;
                        @media only screen and (max-width: 991px) {
                            display: block;
                        }
                        > div{
                            display: table-cell;
                            vertical-align: middle;
                            float: none;
                            padding: 0;
                            @media only screen and (max-width: 991px) {
                                display: block;
                                text-align: center;
                            }
                            .short_desc{
                                padding: 0 5%;
                                @media only screen and (max-width: 991px) {
                                    display: none;
                                }
                                p{
                                    margin-bottom: 30px;
                                    &:last-child{
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .general_footer{
        @include gradient1;
        div.footer_top{
            padding: 60px 0;
            border-bottom: 1px solid $border_color;
            @media only screen and (max-width: 1500px) {
                padding: 40px 0;
            }
            @media only screen and (max-width: 991px) {
                text-align: center;
            }
            .footer_nav{
                @media only screen and (max-width: 991px) {
                    ul{
                        margin-bottom: 20px;
                    }
                }
                p{
                    color: $font_color1;
                    font-size: 16px;
                    line-height: 30px;
                    margin-bottom: 10px;
                }
                ul{
                    li{
                        a{
                            font-size: 12.5px;
                            line-height: 30px;
                            @include anim;
                            color: $font_color2;
                            &:hover{
                                color: $accent_color1;
                            }
                        }
                    }
                }
            }
            .fast_contact{
                p{
                    font-size: 25px;
                    line-height: 35px;
                    font-weight: 800;
                    color: $font_color1;
                    margin-bottom: 20px;
                    @media only screen and (max-width: 1200px) {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
                ul{
                    li{
                        padding-left: 45px;
                        background-repeat: no-repeat;
                        background-position: left center;
                        @media only screen and (max-width: 991px) {
                            background-position: center top;
                            padding-left: 0;
                            padding-top: 45px;
                        }
                        a{
                            font-size: 15px;
                            line-height: 50px;
                            color: $font_color2;
                            @include anim;
                            &:hover{
                                color: $accent_color1;
                            }
                        }
                        &.go_to_form{
                            background-image: url("./../img/fast_contact_icon1.png");
                        }
                        &.go_to_phone{
                            background-image: url("./../img/fast_contact_icon2.png");
                        }
                    }
                }
                .newsletter{
                    border-radius: 10px;
                    overflow: hidden;
                    input{
                        outline: none;
                        //height: 46px;
                        height: 66px;
                        display: inline-block;
                        vertical-align: top;
                        border: none;
                        &.n_input{
                            width: calc(100% - 215px);
                            background: $white;
                            font-size: 13px;
                            color: #9da2aa;
                            font-weight: 700;
                            padding: 0 30px;
                            @media only screen and (max-width: 1200px) {
                                width: 100%;
                                text-align: center;
                            }
                        }
                        &.n_submit{
                            @include gradient3;
                            color: $white;
                            font-size: 14px;
                            font-weight: 700;
                            width: 215px;
                            @media only screen and (max-width: 1200px) {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .footer_bottom{
            padding: 40px 0;
            font-size: 12.5px;
            ul{
                &.left_list{
                    > li{
                        margin-right: 40px;
                        @media only screen and (max-width: 991px) {
                            margin-right: 0;
                            width: 100%;
                            text-align: center;
                            img{
                                margin-bottom: 10px;
                            }
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
                &.right_list{
                    @media only screen and (max-width: 991px) {
                        text-align: center;
                        padding-top: 10px;
                    }
                    > li{
                        margin-right: 25px;
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
                li{
                    display: inline-block;
                    vertical-align: middle;
                    &.rules{
                        padding-left: 40px;
                        @media only screen and (max-width: 1200px) {
                            padding-left: 0;
                        }
                        a{
                            display: inline-block;
                            @include anim;
                            margin-right: 30px;
                            color: $font_color2;
                            font-size: 11.5px;
                            &:hover{
                                color: $accent_color1;
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.top{
    background: rgba(232,232,232,1);
    background: -moz-linear-gradient(left, rgba(232,232,232,1) 0%, rgba(207,218,229,1) 70%, rgba(207,218,229,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(232,232,232,1)), color-stop(70%, rgba(207,218,229,1)), color-stop(100%, rgba(207,218,229,1)));
    background: -webkit-linear-gradient(left, rgba(232,232,232,1) 0%, rgba(207,218,229,1) 70%, rgba(207,218,229,1) 100%);
    background: -o-linear-gradient(left, rgba(232,232,232,1) 0%, rgba(207,218,229,1) 70%, rgba(207,218,229,1) 100%);
    background: -ms-linear-gradient(left, rgba(232,232,232,1) 0%, rgba(207,218,229,1) 70%, rgba(207,218,229,1) 100%);
    background: linear-gradient(to right, rgba(232,232,232,1) 0%, rgba(207,218,229,1) 70%, rgba(207,218,229,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8e8e8', endColorstr='#cfdae5', GradientType=1 );
    .subpage_header{
        height: 205px;
        width: 100%;
        @include vertical;
        @media only screen and (max-width: 1500px) {
            height: 160px;
        }
        @media only screen and (max-width: 991px) {
            height: auto;
            min-height: 120px;
        }
        > div{
            width: 100%;
        }
    }
    &.top_details{
        .subpage_header{
            > div{
                margin-bottom: 45px;
            }
        }
    }
}

.articles{
    padding: 100px 0 80px 0;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0 30px 0;
    }
    .articles_list{
       > ul{
            margin: 0 -20px;
           @media only screen and (max-width: 1500px) {
               margin: 0 -10px;
           }
          > li{
                display: inline-block;
                vertical-align: top;
                padding: 0 20px;
                width: 25%;
                margin-bottom: 40px;
              @media only screen and (max-width: 1500px) {
                  margin-bottom: 20px;
                  padding: 0 10px;
              }
              @media only screen and (max-width: 1200px) {
                  width: 50%;
              }
              @media only screen and (max-width: 991px) {
                  width: 100%;
              }
                article{
                    border: 1px solid $border_color;
                    border-radius: 15px;
                    overflow: hidden;
                    @include anim;
                    &:hover{
                        @include shadow;
                        transform: scale(1.05);
                        div.desc{
                            .short_desc{
                                color: $font_color1;
                            }
                            .arrs{
                                ul{
                                    li{
                                        &.arr_right{
                                            background-image: url("./../img/more_arr2_h.png");
                                        }
                                    }
                                }
                            }
                        }
                    }
                    div{
                        &.image{
                            height: 200px;
                            width: 100%;
                            @include bg_pos;
                            background-size: cover;
                        }
                        &.desc{
                            padding: 25px 30px;
                            .date_header{
                                margin-bottom: 20px;
                                .date{
                                    color: $font_color2;
                                    font-size: 12px;
                                    font-weight: 600;
                                    p{
                                        font-weight: inherit;
                                    }
                                }
                                h2{
                                    color: $font_color1;
                                    font-weight: 600;
                                    font-size: 18px;
                                    line-height: 26px;
                                }
                            }
                            .short_desc{
                                font-size: 13px;
                                line-height: 23px;
                                color: $font_color2;
                                @include anim;
                                p{
                                    display: -webkit-box;
                                    -webkit-line-clamp: 3;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                            .arrs{
                                padding-top: 20px;
                                ul{
                                    li{
                                        &.arr_right{
                                            background-image: url("./../img/more_arr2.png");
                                            &:hover{
                                                background-image: url("./../img/more_arr2.png");
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.inegrations{
    padding: 100px 0;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0;
    }
    .inegrations_list{
        ul{
            display: flex;
            min-height: 750px;
            @media only screen and (max-width: 1500px) {
                min-height: 550px;
            }
            @media only screen and (max-width: 991px) {
                display: block;
            }
            li{
                width: 33.33%;
                padding:85px 60px 55px 60px;
                border: 1px solid $border_color;
                border-radius: 20px;
                @include shadow;
                margin-right: 60px;
                @include anim;
                @media only screen and (max-width: 1500px) {
                    margin-right: 40px;
                    padding: 40px 30px 20px 30px;
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-bottom: 30px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                &:hover{
                    transform: scale(1.03);
                }
                &:last-child{
                    margin-right: 0;
                }
                div{
                    &.logo{
                        text-align: center;
                        margin-bottom: 80px;
                        height: 54px;
                        width: 100%;
                        @include bg_pos;
                        background-size: contain;
                        @media only screen and (max-width: 1500px) {
                            margin-bottom: 40px;
                            height: 34px;
                        }
                    }
                    &.desc{
                        margin-bottom: 55px;
                        @media only screen and (max-width: 1500px) {
                            margin-bottom: 20px;
                        }
                        header{
                            padding-bottom: 30px;
                            margin-bottom: 50px;
                            position: relative;
                            @media only screen and (max-width: 1200px) {
                                margin-bottom: 30px;
                            }
                            &:after{
                                content: '';
                                width: 52px;
                                left: 0;
                                right: 0;
                                margin: 0 auto;
                                height: 2px;
                                @include gradient3;
                                position: absolute;
                                bottom: 0;
                            }
                            h2{
                                color: $font_color1;
                                font-size: 30px;
                                font-weight: 700;
                            }
                        }
                        .default_font{
                            color: $font_color2;
                        }
                    }
                }
            }
        }
    }
}

.integrations_details{
    background-position: center top;
    background-repeat: no-repeat;
    background-image: url("./../img/details_lines.jpg");
    .logo{
        width: 400px;
        padding: 0 55px;
        position: relative;
        top: -50px;
        border: 1px solid $border_color;
        @include shadow;
        background-color: $white;
        margin: 0 auto;
        border-radius: 15px;
        @media only screen and (max-width: 991px) {
           width: 100%;
            padding: 0 30px;
        }
        > div{
            @include bg_pos;
            background-size: contain;

            height: 100px;
            width: 100%;
        }
    }
    .movie_box{
        margin-top: 25px;
        width: 100%;
        @include bg_pos;
        background-size: cover;
        border-radius: 15px;
        position: relative;
        overflow: hidden;
        @media only screen and (max-width: 1500px) {
            margin-top: 0;
        }
        > div{
            &.movie_title{
                @include fill;
                color: $white;
                background: rgba(0,0,0,0.5);
                background: -moz-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(231,56,39,0) 100%);
                background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0.5)), color-stop(100%, rgba(231,56,39,0)));
                background: -webkit-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(231,56,39,0) 100%);
                background: -o-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(231,56,39,0) 100%);
                background: -ms-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(231,56,39,0) 100%);
                background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%, rgba(231,56,39,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#e73827', GradientType=0 );
                top: 0;
                left: 0;
                padding: 30px 35px;
            }
            &.btn_holder{
                height: 350px;
                width: 100%;
                @include vertical;
                position: relative;
                z-index: 2;
                @media only screen and (max-width: 1500px) {
                    height: 300px;
                }
               > div{
                   width: 100%;
                   text-align: center;
                   img{
                       max-width: 110px;
                       cursor: pointer;
                   }
               }
            }
        }
    }
    .integrations_desc{
        display: flex;
        margin-top: 80px;
        @media only screen and (max-width: 991px) {
         display: block;
            margin-top: 40px;
        }
        > div{
            &.desc_side{
                width: 62%;
                margin-right: 80px;
                @media only screen and (max-width: 1500px) {
                    margin-right: 40px;
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 40px;
                }
                .desc{
                    border: 1px solid $border_color;
                    padding: 60px;
                    border-radius: 15px;
                    @include shadow;
                    @media only screen and (max-width: 1500px) {
                        padding: 30px;
                    }
                }
            }
            &.download_side{
                width: 38%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                .side_box{
                    padding:42px 54px;
                    border-radius: 15px;
                    background:#f6f6f6;
                    margin-bottom: 40px;
                    @media only screen and (max-width: 1500px) {
                        padding: 22px 30px;
                    }
                    @media only screen and (max-width: 991px) {
                      &:last-child{
                          margin-bottom: 0;
                      }
                    }
                    .section_header2{
                        padding-bottom: 30px;
                        margin-bottom: 40px;
                    }
                    ul{
                        li{
                            height: 35px;
                            width: 190px;

                            margin-bottom: 27px;
                            position: relative;
                            .image{
                                @include fill;
                                @include bg_pos;
                                background-size: contain;
                            }
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                    .default_font{
                        margin-bottom: 45px;
                    }
                }
            }
        }
    }
}

.section_header2{
    position: relative;
    padding-bottom: 40px;
    margin-bottom: 50px;
    color: $font_color1;
    @media only screen and (max-width: 991px) {
       padding-bottom: 25px;
        margin-bottom: 25px;
    }
    &.with_icon{
        padding-left: 40px;
        background-position: left top 5px;
        background-repeat: no-repeat;
        background-image: url("./../img/d_icon1.png");
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
    h2{
        font-size: 30px;
        line-height: 40px;
    }
    h3{
        font-size: 24px;
        line-height: 34px;
    }
    &:after{
        content: '';
        height: 2px;
        width: 52px;
        @include gradient3;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}

.about_configurator{
    padding: 100px 0 0 0;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0 0 0;
    }
    .about_configurator_list{
        > ul{
            >li{
                margin-bottom: 100px;
                @media only screen and (max-width: 1500px) {
                    margin-bottom: 50px;
                }
                @media only screen and (max-width: 991px) {
                    .row{
                        display: flex;
                        flex-flow: column;
                        align-items: stretch;
                        .col-md-5{
                            order: 1;
                            text-align: center;
                            margin-bottom: 30px;
                        }
                        .col-md-6{
                            order: 2;
                        }
                    }
                }
                .section_header{
                    h2{
                        font-size: 21px;
                        line-height: 31px;
                        color: $font_color1;
                    }
                }
            }
        }
    }
}

.demo_banner{
    width: 100%;
    position: relative;
    @include bg_pos;
    background-size: cover;
    border-radius: 15px;
    overflow: hidden;
    .cover{
        @include fill;
        background: rgba(0,0,0,0.4);
        .image{
            position: absolute;
            top: 6.5%;
            right: 0;
            height: 87%;
            width: 30%;
            @include bg_pos;
            background-size: 100% auto;
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
    }
    > div{
        &.banner_content{
            height: 400px;
            @include vertical;
            position: relative;
            z-index: 3;
            color: $white;
            @media only screen and (max-width: 1500px) {
                height: 300px;
            }
            @media only screen and (max-width: 1200px) {
                height: 250px;
            }
            .content_holder{
                width: 100%;
                text-align: center;
                @media only screen and (max-width: 991px) {
                    padding: 0 15px;
                }
                h2{
                    color: $white;
                }
            }
        }
    }
}

.industry_solutions{
    padding-top: 100px;
    @media only screen and (max-width: 1500px) {
        padding-top: 50px;
    }
    .industry_solutions_list{
        ul{
            li{
                margin-bottom: 100px;
                @media only screen and (max-width: 1500px) {
                    margin-bottom: 50px;
                }
                .row{
                    margin: 0 -40px;
                    @media only screen and (max-width: 1500px) {
                        margin: 0 -20px;
                    }
                    > div{
                        padding: 0 40px;
                        @media only screen and (max-width: 1500px) {
                            padding: 0 20px;
                        }
                    }
                }
                .image{
                    width: 100%;
                    height: 0;
                    padding-bottom: 53%;
                    border-radius: 15px;
                    border: 1px solid $border_color;
                    @include shadow;
                    @include bg_pos;
                    background-size: contain;
                    @media only screen and (max-width: 991px) {
                        margin-bottom: 30px;
                    }
                }
                .desc{
                    .section_header2{
                        padding-bottom: 34px;
                        margin-bottom: 35px;
                    }
                    .default_font{
                        margin-bottom: 40px;
                    }
                }
            }
        }
    }
}

.industry_solutions_details{
    padding: 100px 0 0 0;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0 0 0;
    }
    .demo_banner{
        .banner_content{
            height: 500px;
            @media only screen and (max-width: 1500px) {
                height: 400px;
            }
            @media only screen and (max-width: 1200px) {
                height: 300px;
            }
            @media only screen and (max-width: 991px) {
                height: auto;
                min-height: 250px;
            }
        }
    }
    .industry_desc_list{
        padding-top: 100px;
        @media only screen and (max-width: 1500px) {
            padding-top: 50px;
        }
        > ul{
           > li{
               margin-bottom: 100px;
               @media only screen and (max-width: 1500px) {
                    margin-bottom: 50px;
               }
               @media only screen and (max-width: 991px) {
                   display: flex;
                   flex-flow: column;
                   align-items: stretch;
               }
               &:last-child{
                   margin-bottom: 0;
               }
              > div{
                  display: inline-block;
                  vertical-align: middle;


                  &.desc{
                      width: 55%;
                      @media only screen and (max-width: 991px) {
                            width: 100%;
                          order: 2;
                      }
                        .short_desc{
                            .default_font{
                                padding-top: 30px;
                            }
                        }
                  }
                  &.image{
                      @include bg_pos;
                      background-size: contain;
                      height: 0;
                      padding-bottom: 26%;
                      width: 45%;
                      @media only screen and (max-width: 991px) {
                        width: 100%;
                          order: 1;
                          margin-bottom: 30px;
                      }
                  }
              }
            }
        }
    }
}

.other{
    padding: 100px 0 70px 0;
    @media only screen and (max-width: 1500px) {
       padding: 50px 0;
    }
    @media only screen and (max-width: 991px) {
        text-align: center;
        .arrs{
            margin-top: 30px;
            ul{
                li{
                    width: 50%;
                    margin: 0 !important;
                }
            }
        }
    }
    @include gradient1;
    .carousel_content{
        overflow: hidden;
        ul{
            margin: 0 -30px;
            position: relative;
            padding-bottom: 30px;
            white-space: nowrap;
            @media only screen and (max-width: 1200px) {
                margin: 0 -15px;
            }
            li{
                white-space: normal;
                padding: 0 30px;
                display: inline-block;
                vertical-align: top;
                width: 33.33%;
                @media only screen and (max-width: 1200px) {
                    padding: 0 15px;
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                article{
                    background: $white;
                    border: 1px solid $border_color;
                    border-radius: 15px;
                    @include shadow;
                    padding: 45px 55px 60px 55px;
                    @media only screen and (max-width: 991px) {
                        padding: 35px;
                    }
                    .image{
                        @include bg_pos;
                        background-size: contain;
                        width: 100%;
                        height: 0;
                        padding-bottom: 74%;
                        margin-bottom: 40px;
                        @media only screen and (max-width: 991px) {
                            padding-bottom: 33%;
                        }
                    }
                    .desc{
                        .section_header2{
                            &:after{
                                margin: 0 auto;
                                right: 0;
                            }
                        }
                        .default_font{
                            margin-bottom: 50px;
                            color: $font_color2;
                            @media only screen and (max-width: 991px) {
                                margin-bottom: 30px;
                            }
                            p{
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
            }
        }
    }
}

.universal{
    padding: 100px 0;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0;
    }
    .section_header{
        h2{
            color: $font_color1;
        }
    }
    div.desc_box{
        padding-bottom: 80px;
        margin-bottom: 80px;
        border-bottom: 1px solid $border_color;
        @media only screen and (max-width: 1500px) {
            padding-bottom: 40px;
            margin-bottom: 40px;
        }
        .row{
            margin: 0 -20px;
            margin-bottom: 30px;
            @media only screen and (max-width: 991px) {
               &:last-child{
                   margin-bottom: 0;
               }
            }
            > div{
                padding: 0 20px;
            }
        }
        .image{
            @include bg_pos;
            background-size: cover;
            width: 100%;
            height: 0;
            padding-bottom: 58%;
            border-radius: 15px;
            @media only screen and (max-width: 991px) {
                margin-bottom: 30px;
            }
        }
        .upper_desc{
            margin-bottom: 30px;
            color: $font_color1;
        }
        .movie_box{
            width: 100%;
            @include bg_pos;
            background-size: cover;
            border-radius: 15px;
            position: relative;
            overflow: hidden;
            @media only screen and (max-width: 991px) {
                margin-top: 30px;
            }
            > div{
                &.movie_title{
                    @include fill;
                    color: $white;
                    background: rgba(0,0,0,0.5);
                    background: -moz-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(231,56,39,0) 100%);
                    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0.5)), color-stop(100%, rgba(231,56,39,0)));
                    background: -webkit-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(231,56,39,0) 100%);
                    background: -o-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(231,56,39,0) 100%);
                    background: -ms-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(231,56,39,0) 100%);
                    background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%, rgba(231,56,39,0) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#e73827', GradientType=0 );
                    top: 0;
                    left: 0;
                    padding: 30px 35px;
                }
                &.btn_holder{
                    height: 350px;
                    width: 100%;
                    @include vertical;
                    position: relative;
                    z-index: 2;
                    @media only screen and (max-width: 1500px) {
                        height: 300px;
                    }
                    @media only screen and (max-width: 1200px) {
                        height: 250px;
                    }
                    > div{
                        width: 100%;
                        text-align: center;
                        img{
                            max-width: 110px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    div.download{
        padding-bottom: 80px;
        margin-bottom: 80px;
        border-bottom: 1px solid $border_color;
        @media only screen and (max-width: 1500px) {
            padding-bottom: 40px;
            margin-bottom: 40px;
        }
        .download_list{
            ul{
                li{
                    width: 100%;
                    margin-bottom: 20px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    .download_box{
                        padding: 12px 80px 12px 20px;
                        display: inline-block;
                        border: 1px solid $border_color;
                        border-radius: 10px;
                        background-position: right 30px center;
                        background-repeat: no-repeat;
                        background-image: url('/img/d_icon2.png');
                        min-width: 470px;
                        @media only screen and (max-width: 991px) {
                            min-width: 0;
                            width: 100%;
                        }
                        > div{
                            padding-left: 57px;
                            background-repeat: no-repeat;
                            background-position: left center;
                            background-image: url('/img/d_icon1.png');
                            min-height: 33px;
                            font-size:13px;
                            line-height: 20px;
                            color: $font_color1;
                            > p{
                                padding-top: 10px;
                                font-weight: 600;
                            }
                            span{
                                color: $font_color2;
                            }
                        }
                    }
                }
            }
        }
    }
    .gallery{
        padding-bottom: 50px;
        @media only screen and (max-width: 1500px) {
            padding-bottom: 40px;
        }
        .gal_list{
            ul{
                margin: 0 -15px;
                @media only screen and (max-width: 991px) {
                    margin: 0 -5px;
                }
                li{
                    padding: 0 15px;
                    display: inline-block;
                    vertical-align: top;
                    width: calc(100% / 6);
                    margin-bottom: 30px;
                    @media only screen and (max-width: 991px) {
                        width: calc(100% / 2);
                        padding: 0 5px;
                        margin-bottom: 10px;
                    }
                    .image{
                        border-radius: 10px;
                        width: 100%;
                        height: 0;
                        padding-bottom: 72%;
                        @include bg_pos;
                        background-size: cover;
                    }
                }
            }
        }
    }
}

.contact{
    padding: 100px 0;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0;
    }
    .container{
        > div.row{
            margin: 0 -67px;
            @media only screen and (max-width: 1500px) {
                margin: 0 -30px;
            }
            @media only screen and (max-width: 1200px) {
                margin: 0 -15px;
            }
            > div{
                padding: 0 67px;
                @media only screen and (max-width: 1500px) {
                    padding: 0 30px;
                }
                @media only screen and (max-width: 1200px) {
                    padding: 0 15px;
                }
            }
        }
    }
    .contact_boxes{
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
        }
       > ul{
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            -ms-flex-align: flex-end;
            -webkit-align-items: flex-end;;
            -webkit-box-align: flex-end;;

            align-items: flex-end;;
           margin: 0 -20px;
           @media only screen and (max-width: 1200px) {
                margin: 0 -10px;
           }
           @media only screen and (max-width: 991px) {
               margin: 0;
           }
           > li{
                width: 50%;
               padding: 0 20px;
               margin-bottom: 40px;
               @media only screen and (max-width: 1200px) {
                    padding: 0 10px;
                   margin-bottom: 20px;
               }
               @media only screen and (max-width: 991px) {
                    width: 100%;
                   padding: 0;
               }
                .contact_box{
                    padding: 35px;
                    border-radius: 15px;
                    border: 1px solid $border_color;
                    background-color: #fafafa;
                    @media only screen and (max-width: 1200px) {
                        padding: 25px;
                    }
                    &.main_box{
                        @include shadow;
                        background-color: $white;
                    }
                }
                &:nth-child(2n-1){
                    margin-right: 0;
                }
               header{
                    margin-bottom: 24px;
               }
               ul{
                   li{
                       padding-left: 33px;
                       background-position: left top;
                       background-repeat: no-repeat;
                       margin-bottom: 24px;

                       &:last-child{
                           margin-bottom: 0;
                       }
                       .default_font{
                           line-height: 24px;
                       }
                       &.adress{
                            background-image: url('/img/contact_icon1.png');
                       }
                       &.mail{
                           background-image: url('/img/contact_icon2.png');
                       }
                       &.phone{
                           background-image: url('/img/contact_icon3.png');
                       }
                   }
               }
            }
        }
    }
    .contact_form{
        div.holder{
            margin-bottom: 10px;
            ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color:#a4afba;
            }
            ::-moz-placeholder { /* Firefox 19+ */
                color:#a4afba;
            }
            :-ms-input-placeholder { /* IE 10+ */
                color:#a4afba;
            }
            :-moz-placeholder { /* Firefox 18- */
                color:#a4afba;
            }
            &.text-right{
                padding-top: 50px;
                @media only screen and (max-width: 991px) {
                    padding-top: 15px;
                }
            }
            input{
                outline: none;
                &.input{
                    color:#a4afba;
                    font-size: 14px;
                    height: 54px;
                    width: 100%;
                    border: 1px solid $border_color;
                    border-radius: 15px;
                    padding-left: 64px;
                    background-position: left 28px center;
                    background-repeat: no-repeat;
                    &.name{
                        background-image: url("./../img/form_icon1.png");
                    }
                    &.mail{
                        background-image: url("./../img/form_icon2.png");
                    }
                    &.phone{
                        background-image: url("./../img/form_icon3.png");
                    }
                }
                &.submit{
                    display: inline-block;
                    line-height: 44px;
                    font-size: 14px;
                    font-weight: 700;
                    @include gradient3;
                    padding: 0 38px;
                    color: $white;
                    border-radius: 5px;
                    text-transform: uppercase;
                    border:none;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                }
            }
            .textarea{
                color:#a4afba;
                font-size: 14px;
                min-height: 165px;
                width: 100%;
                border: 1px solid $border_color;
                border-radius: 15px;
                padding-left: 64px;
                padding-top: 13px;
                background-position: left 28px top 20px;
                background-repeat: no-repeat;
                outline: none;
                resize: none;
                &.desc{
                    background-image: url("./../img/form_icon4.png");
                }
            }
            &.rules{
                > div{
                    display: inline-block;
                    vertical-align: top;
                    &.check_holder{
                        width: 22px;
                        .checkbox_image_box{
                            width: 100%;
                            > div{
                                width: 100%;
                                height: 0;
                                padding-bottom: 91%;
                                background-color: $white;
                                border: 1px solid $border_color;
                                border-radius: 5px;
                                background-repeat: no-repeat;
                                background-position: center;
                                &.yes{
                                    &.on{
                                        background-image: url("./../img/check_icon.png");
                                    }
                                }
                            }
                        }
                    }
                    &.check_desc{
                        padding-top: 2px;
                        width: calc(100% - 22px);
                        padding-left: 17px;
                        font-size: 12px;
                        line-height: 22px;
                        color: $font_color1;
                        div{
                            display: inline-block;
                        }
                        span{

                            cursor: pointer;
                        }
                        .rules_hidden{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.map{
    height: 400px;
    width: 100%;
    position: relative;
    @media only screen and (max-width: 1500px) {
        height: 300px;
    }
    @media only screen and (max-width: 1200px) {
        height: 250px;
    }
    iframe{
        @include fill;
        border: none;
    }
}

.movie_popup{
    position: fixed;
    height: 100vh;
    width: 100%;
    background: rgba(255,255,255,0.9);
    z-index: 9999;
    top:0;
    left: 0;
    display: none;
    .popup_holder{
        width: 100%;
        @include vertical;
        @include  fill;
        > div{
            width: 100%;
        }
        .close_popup{
            line-height: 40px;
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 700;
            position: absolute;
            right: 0px;
            top: -40px;
            cursor: pointer;
            color: #000000;
        }
        .popup_box{
            width: 50%;
            height: 0;
            padding-bottom: 30%;
            background: $white;
            margin: 0 auto;
            position: relative;
            @include shadow;
            iframe{
                @include fill;
                border: none;
            }
            @media only screen and (max-width: 991px) {
                width: 80%;
                padding-bottom: 60%;
            }
        }
    }
}
.no_height{
    height: auto!important;
    >div{
        height: auto!important;
    }
}
